require('./bootstrap');
require('./jSwitch');
require('jquery-countdown');
var owlCarousel = require('owl.carousel');
var floatingIndex = 0;
var openedAsPopup = false;

$( document ).ready(function() {

    var reCAPTCHAkey = $('meta[name="recaptcha"]').attr('content');
    grecaptcha.ready(function() {
        grecaptcha.execute(reCAPTCHAkey, {action: 'submit'}).then(function(token) {
          jQuery('#sign-up-form').append('<input type="hidden" value="'+token+'" name="captchaToken" />');
          jQuery('#sign-in-form').append('<input type="hidden" value="'+token+'" name="captchaToken" />');
          jQuery('#sign-up-form-page').append('<input type="hidden" value="'+token+'" name="captchaToken" />');
          jQuery('#sign-in-form-page').append('<input type="hidden" value="'+token+'" name="captchaToken" />');
          jQuery('#password-forget').append('<input type="hidden" value="'+token+'" name="captchaToken" />');
          jQuery('#paymentform').append('<input type="hidden" value="'+token+'" name="captchaToken" />');
        });
    }); 

    if($('.frontpage-bg').length > 0){
        $('.frontpage-bg').addClass('doc-ready');
    }

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    if($('#notification_content').length > 0 && $('#notification_content').find('.notification').length > 0){
      $.each($('#notification_content').find('.notification'), function(i,k){
        $.hide_notification($(k));
      })
    }


    if($('.questions-nav-buttons').length > 0){
      $('.questions-nav-buttons').on('click','button',function(){
          $(this).closest('form').find('.loader-box').addClass('showed');
      });
    }

    if($('.input-mode-questions').length > 0){

      $('.answer-result').on('change','input.check-answer',function(){
          $('.answer-result').find('.right-answer').removeClass('right-answer');
          $(this).closest('label').addClass('right-answer');
      });


    }

    if($('#scroll-to-top-link').length > 0){
        $("a#scroll-to-top-link").on('click',function() {
          $("html, body").animate({ scrollTop: 0 }, "slow");
          return false;
        });
    }

    if($('.scrollable-button').length > 0){
        $(".scrollable-button").on('click',function() {
          var target = $(this).data('target');
          $("html, body").animate({ scrollTop: $(target).offset().top }, "slow");
          return false;
        });
    }



    if($('.pagination-data-show').length > 0){

        var pageSize = 125;
        var incremSlide = 6;
        var startPage = 0;
        var numberPage = ($(".question-unique-number.current-question-place").length > 0) ? Math.floor(parseInt($(".question-unique-number.current-question-place").data('key')) / pageSize)+1 : 1;
        if(!numberPage)
          numberPage = 1;

        var pageCount =  $(".question-unique-number").length / pageSize;
        var totalSlidepPage = Math.floor(pageCount / incremSlide);
            
        for(var i = 0 ; i<pageCount;i++){
            $("#pagin").append('<li><a href="#">'+(i+1)+'</a></li> ');
        }

        showPage = function(page) {
            $(".question-unique-number").hide();
            $(".question-unique-number").each(function(n) {
                if (n >= pageSize * (page - 1) && n < pageSize * page){
                    $(this).show();
                    $("#pagin li a").eq(parseInt((page - 1))).addClass("current");
                }
            });        
        }
            
        showPage(numberPage);

        $("#pagin li a").click(function() {
           $("#pagin li a").removeClass("current");
           $(this).addClass("current");
           showPage(parseInt($(this).text()));
        });


    }





    if($("#rateForm").length > 0){
      $("#rateForm").on('click','input.rb', function(e) {

          e.preventDefault(); 
         
          var domLink = $(this),
              form = $(this).closest('form'),
              stars = $(domLink).val();                  

            if(stars){
              var url = $(form).attr('action');
              $.ajax({
                     type: "POST",
                     url: url,
                     data: {'stars':stars}, 
                     success: function(data)
                     {

                      $(data).appendTo($('body')).promise().done(function(){ 
                         $(form).hide();
                         $('body').find('#leaveTestRate').modal('show');
                      });

                     },
                     error: function(data)
                     {
                        // $(form).submit();                        

                     }
                   });
            }else{
                // $(form).submit();
            }

          return false;

      });

    }



    if($('#help-center-section').length > 0){
        $.helpCenterEvents();
    }

    if($('#results-animation').length > 0){
        var resAnHld = $('#results-animation'),
            maxScore = $(resAnHld).find('.all-answers'),
            needScore = $(resAnHld).find('.needed-percents'),
            userScore = $(resAnHld).find('.passed-exam-score');

            var path1 = $('#score-text').find('path')[0];
            var pathLength = path1.getTotalLength();

            var path2 = $('#score-text').find('path')[1];
            var pathLength2 = path2.getTotalLength();

            $(path1).css({
              'stroke-dashoffset': pathLength,
              'stroke-dasharray': pathLength + ' ' + pathLength
            });

            $(path2).css({
              'stroke-dashoffset': pathLength2,
              'stroke-dasharray': pathLength2 + ' ' + pathLength2
            });        

            $(maxScore).animate({
                width: $(maxScore).data('percents')+'%'
              }, 1000, function() {

                $(maxScore).find('.percents-with-text').addClass('animation-complete').delay(400).queue(function(){
                    $(this).dequeue();
                    $(maxScore).find('.all-answers-percents').addClass('animation-complete').delay(800).queue(function(){
                        $(maxScore).find('.percents-explained').addClass('animation-complete');
                        $(this).dequeue();
                    });
                });


                $('#score-text').addClass('animation-complete').delay(400).queue(function(){
                    $(this).dequeue();
                    $(path1).animate({
                      'stroke-dashoffset': 0
                    }, 2200, function() {
                      $(path2).animate({
                          'stroke-dashoffset': 0
                        }, 1000, function() {

                      });
                    });

                });


                

                $(needScore).animate({
                    width: $(needScore).data('percents')+'%'
                  }, 900, function() {

                    $(needScore).find('.percents-with-text').addClass('animation-complete').delay(400).queue(function(){
                        $(this).dequeue();
                        $(needScore).find('.need-to-pass').addClass('animation-complete').delay(800).queue(function(){
                            $(needScore).find('.percents-explained').addClass('animation-complete');
                            $(this).dequeue();
                        });
                    });


                    if($(userScore).data('percents') < 15)
                          $(userScore).find('.percents-with-text').addClass('too-low-score');

                    $(userScore).animate({
                        width: $(userScore).data('percents')+'%'
                      }, 1200, function() {

                        if($(userScore).data('percents') >= $(needScore).data('percents')){
                          $(this).addClass('passed').delay(200).queue(function(){
                            $(this).dequeue();
                            $(this).find('.percents-with-text').addClass('animation-complete');
                          });  
                        }else{

                          $(userScore).find('.percents-with-text').addClass('animation-complete');                      
                        }
                       

                    });

                });

            });

    }


    if($("#question-answer").length > 0){
      $("#question-answer").on('click','#traning-mode-submit-button', function(e) {

          e.preventDefault(); 
         
          var domLink = $(this),
              form = $(this).closest('form'),
              test = $(this).data('test'),
              question = $(this).data('question');

               $(form).find('.loader-box').addClass('showed');
                   
          var selectedAnswer = $(form).find("input[name='answer']:checked").val();
          // if(selectedAnswer){

            if(test && question){
              var url = '/'+test+'/'+question;
              $.ajax({
                     type: "POST",
                     url: url,
                     data: {'test':test,'question':question,'answer':selectedAnswer}, 
                     success: function(data)
                     {
                      $(form).find('.form-question-content').hide();
                      $(data).appendTo($(form)).promise().done(function(){ 
                          $(form).find('.loader-box').removeClass('showed');
                      });

                     },
                     error: function(data)
                     {
                        $(form).submit();                        

                     }
                   });
            }else{
                $(form).submit();
            }
          // }else{
          //     $.append_notification('error','Please select answer')
          // }

          return false;

      });


      $("#question-answer").on('click','#exam-mode-flag-button', function(e) {

          e.preventDefault(); 

          var domLink = $(this),
              form = $(this).closest('form'),
              test = $(this).data('test'),
              question = $(this).data('question'),
              mode = $(form).data('mode');

            if(test && question){
              var url = '/flag-question';
              $.ajax({
                     type: "POST",
                     url: url,
                     data: {'slug':test,'question':question,'mode':mode}, 
                     success: function(data)
                     {
                        if(data.status){                         
                          $(domLink).toggleClass('flagged-question')
                          $.append_notification('success',data.message);
                        }
                        else
                          $.append_notification('error',data.message);

                     },
                     error: function(data)
                     {
                          $.append_notification('error','Question could not be flagged now.Please Try again Later!');                     
                     }
                   });
            }else{
                $(form).submit();
            }

          return false;

      });


    }


    if($("#reviewForm").length > 0){
      $("#reviewForm").submit(function(e) {

          e.preventDefault(); 

          var form = $(this);
          var url = form.attr('action');

          $.ajax({
                 type: "POST",
                 url: url,
                 data: form.serialize(), // serializes the form's elements.
                 success: function(data)
                 {
                    $('#leaveTestReview').find('.popup-modal-body').html(data);
                 },
                 error: function(data)
                 {
                    if(data.responseJSON && data.responseJSON.message){
                      $('#leaveTestReview').find('.login-error-msg').addClass('showed').html('<span>'+data.responseJSON.message+'</span>'); 
                    }else
                      $('#leaveTestReview').find('.popup-modal-body').html(data);
                    

                 }
               });


      });
    }

    if($('#slide-on-small-screens').length > 0){
        var reviewSlider = false;
        if($(window).width() < 991){

          reviewSlider = $('#slide-on-small-screens').owlCarousel({
              loop:true,
              margin:15,
              responsiveClass:true,
              dots: false,
              nav: true,
              autoplay: false,
              autoplayHoverPause: true,
              autoplayTimeout: 8000,
              navText: ['<i class="far fa-arrow-alt-circle-left"></i>','<i class="far fa-arrow-alt-circle-right"></i>'],
              items:1,
              onInitialize: function(event){
                  
              },
              onDragged: function(event){
                  
              },
              onRefresh: function(event){
                  
              }
          });

        }

        $(window).resize(function() {
            clearTimeout(window.resizedFinished);
            window.resizedFinished = setTimeout(function(){
              if(reviewSlider && $(window).width() >= 991){
                reviewSlider.trigger('destroy.owl.carousel');
                reviewSlider = false;
              }
              else{

                  if($(window).width() < 991){
                    if(reviewSlider)
                      reviewSlider.trigger('refresh.owl.carousel');
                    else{
                        reviewSlider = $('#slide-on-small-screens').owlCarousel({
                            loop:true,
                            margin:15,
                            responsiveClass:true,
                            dots: false,
                            nav: true,
                            autoplay: false,
                            autoplayHoverPause: true,
                            autoplayTimeout: 8000,
                            navText: ['<i class="far fa-arrow-alt-circle-left"></i>','<i class="far fa-arrow-alt-circle-right"></i>'],
                            items:1,
                            onInitialize: function(event){
                                
                            },
                            onDragged: function(event){
                                
                            },
                            onRefresh: function(event){
                                
                            }
                        });
                    }
                  }
              }

            }, 400);
        });


    }

    if($('.counter-on-page').length > 0){
      $.each($('.counter-on-page'),function(i,k){

          var currentCounter = $(k).find('.total-clock-datetime');

          var startDate = $(currentCounter).attr('datetime');

          if($(currentCounter).data('countdown')){
               
                var secondsPass = new Date().getTime() + (startDate*1000);

                $(currentCounter).find('.total-clock-time').countdown(secondsPass, {elapse: true})
                .on('update.countdown', function(event) {
                  var $this = $(this);
                  if (event.elapsed) {
                    $this.html(event.strftime('%I:%M:%S'));
                  } else {
                    $this.html(event.strftime('%I:%M:%S'));
                  }
                });

          }else{

              var date = new Date(null);
                  date.setSeconds(startDate); 
                  var timeString = date.toISOString().substr(11, 8);
                  $(currentCounter).find('.total-clock-time').html(timeString);
          }
      });

    }


     if($('#authModal').length > 0){

        $('#authModal .popup-modal-body').on('click','a.pill-tab-link', function (e) {
          e.preventDefault();
          // First Hide all other tabs and remove link class
          $('#authModal').find('.tab-pane').removeClass('show').removeClass('active');
          $('#authModal').find('.popup-modal-body a.nav-link').removeClass('active');

          var idShow = $(this).attr('href').replace("#", "");

          $('#authModal').find('div[id^="'+idShow+'"]').tab('show');
          $(this).addClass('active');

          return false;
        })


    }

    if($('#exam-library').length > 0){

        $('#exam-library').on('click','a.exam-tabs', function (e) {
            e.preventDefault();
            // First Hide all other tabs and remove link class
            $('#exam-library').find('.tab-pane').removeClass('show').removeClass('active');
            $('#exam-library').find('a.exam-tabs').removeClass('active');

            var idShow = $(this).attr('href');

            $('#exam-library').find(idShow).tab('show');
            $(this).addClass('active');

            return false;
        })


    }

    var hasFloatingPage = false;
    if($('.one-page-scroll').length > 0 && $(window).width() > 1339)
      hasFloatingPage = true;

    if(hasFloatingPage){      
       $.checkViewports();

       $("#help-center-float").on('click',function(e) {

          e.preventDefault(); 
          $('#help-center-opener').html('<div class="box"><div class="loader-10"></div></div>').addClass('opened');

          $.ajax({
                 type: "POST",
                 url: "/open-help-center",
                 data: {}, 
                 success: function(data)
                 {  

                    setTimeout(function(){
                      $('#help-center-opener').html(data).promise().done(function(){ 
                          openedAsPopup = true;
                          var closeBtn = '<div class="help-main-close-btn"><a href="javascript:;" id="close-btn-help-center"><i class="fas fa-minus"></i></a></div>';
                          $(closeBtn).insertAfter($('#help-center-opener').find('#help-center-button'));

                          var goToHelpCenter = '<div class="appender-link"><a href="/help-center">Go to Help Center</a></div>';
                          $(goToHelpCenter).appendTo($('#help-center-opener').find('.all-page-container'));
                          $.helpCenterEvents();
                      });
                    },800)
                    // 
                    
                 },
                 error: function(data)
                 {
                  // console.log(data);
                    window.location.href = '/help-center';
                    

                 }
               });

          return false;

      });

    }


    $(window).scroll(function(){
        if($(window).scrollTop() > 0){
            $('.profile-line-1').addClass('scrolled');
            $('.profile-line-2').addClass('scrolled');
            $('.bg-header').addClass('scrolled');
            $('.bg-header-fixed').addClass('scrolled');

        }
        else{
            $('.profile-line-1').removeClass('scrolled');
            $('.profile-line-2').removeClass('scrolled');
            $('.bg-header').removeClass('scrolled');
            $('.bg-header-fixed').removeClass('scrolled');
        }

        if(hasFloatingPage){
            $.checkViewports();
        }

    });


    if($(window).scrollTop() > 40){
        $('.profile-line-1').addClass('scrolled');
        $('.profile-line-2').addClass('scrolled');
        $('.bg-header').addClass('scrolled');
        $('.bg-header-fixed').addClass('scrolled');        

    }








    if($('#products-choose').length > 0){
        var productSlider = false;
        if($(window).width() < 991){

          productSlider = $('#products-choose').owlCarousel({
              startPosition: 1,
              loop:true,
              margin:15,
              responsiveClass:true,
              dots: false,
              nav: true,
              autoplay: false,
              autoplayHoverPause: true,
              autoplayTimeout: 8000,
              navText: ['<i class="far fa-arrow-alt-circle-left"></i>','<i class="far fa-arrow-alt-circle-right"></i>'],
              items:1,
              onInitialize: function(event){
                  
              },
              onDragged: function(event){
                  
              },
              onRefresh: function(event){
                  
              }
          });

        }

        $(window).resize(function() {
            clearTimeout(window.resizedFinished);
            window.resizedFinished = setTimeout(function(){
              if(productSlider && $(window).width() >= 991){
                productSlider.trigger('destroy.owl.carousel');
                productSlider = false;
              }
              else{

                  if($(window).width() < 991){
                    if(productSlider)
                      productSlider.trigger('refresh.owl.carousel');
                    else{
                        productSlider = $('#products-choose').owlCarousel({
                            startPosition: 1,
                            loop:true,
                            margin:15,
                            responsiveClass:true,
                            dots: false,
                            nav: true,
                            autoplay: false,
                            autoplayHoverPause: true,
                            autoplayTimeout: 8000,
                            navText: ['<i class="far fa-arrow-alt-circle-left"></i>','<i class="far fa-arrow-alt-circle-right"></i>'],
                            items:1,
                            onInitialize: function(event){
                                
                            },
                            onDragged: function(event){
                                
                            },
                            onRefresh: function(event){
                                
                            }
                        });
                    }
                  }
              }

            }, 400);
        });


    }



    if($('#searchDropdownInline').length > 0){
      $('#searchDropdownInline').on('click', function (e) {

        $('#searchDropdownMenuHolder').addClass('show');
        $('#searchDropdownMenu').addClass('show');
        $('.fullBodyOverlay').addClass('showed');
        return false;
      })
    }

    if($('#searchDropdownMenu').length > 0){

        $(document).on('click',function(e) 
        {

              var sitemapFilter = $("#searchDropdownMenuHolder");
              if (!sitemapFilter.is(e.target) && sitemapFilter.has(e.target).length === 0) 
              {
                  $('#searchDropdownResults').empty();
                  $('#searchDropdownMenuHolder').removeClass('show');
                  $('#searchDropdownMenu').removeClass('show');
                  $('.fullBodyOverlay').removeClass('showed');
              }

             

        });




        var searchFormFilter = false;
        $('#searchDropdownMenu').on('keyup','#search-input' ,function(e){         
          var value = $(this).val();

          if(value){
              clearTimeout(searchFormFilter);

              if( value.length < 3 ){
                $('#searchDropdownResults').empty();
                
                return; 
              } 

              searchFormFilter = setTimeout(function () {
                  $.filter_sitemap_search(value,'#searchDropdownResults');                    

              }, 400);
          }
              
          return false;

        }).on('focus','input#search-input' ,function(e){         
          e.preventDefault();

          var value = $(this).val();
          if(value){
              if( value.length > 3 ){
                  clearTimeout(searchFormFilter);
                  searchFormFilter = setTimeout(function () {
                      $.filter_sitemap_search(value,'#searchDropdownResults')
                  }, 400);

              }                 
          }

        });
    }





    $('body').find('.jSwitch').jSwitch();


    if($('#bg-line-1-svg').length > 0 && $('#bg-line-3-svg').length > 0 && $('#bg-line-2-svg').length > 0 && $('#scroll-progress-bar').length > 0){

        var path = $('#bg-line-1-svg').find('path')[0];
        var pathLength = path.getTotalLength();

        path.style.strokeDasharray = pathLength + ' ' + pathLength;

        path.style.strokeDashoffset = pathLength;
        path.getBoundingClientRect();

        var path2 = $('#bg-line-2-svg').find('path')[0];

        var pathLength2 = path2.getTotalLength();

        path2.style.strokeDasharray = pathLength2 + ' ' + pathLength2;

        path2.style.strokeDashoffset = pathLength2;
        path2.getBoundingClientRect();

        var path3 = $('#bg-line-3-svg').find('path')[0];

        var pathLength3 = path3.getTotalLength();

        path3.style.strokeDasharray = pathLength3 + ' ' + pathLength3;

        path3.style.strokeDashoffset = pathLength3;
        path3.getBoundingClientRect();


        var path4 = $('#scroll-progress-bar');
            $(path4).css({'height':'0%'});
        
        window.addEventListener("scroll", function(e) {

          var scrollPercentage = (document.documentElement.scrollTop + document.body.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight);
          var drawLength3 = pathLength3 * scrollPercentage;
          path3.style.strokeDashoffset = pathLength3 - drawLength3;
          if (scrollPercentage >= 0.99) {
            path3.style.strokeDasharray = "none";
            
          } else {
            path3.style.strokeDasharray = pathLength3 + ' ' + pathLength3;
          }

          var drawLength = pathLength * scrollPercentage;
          

          path.style.strokeDashoffset = pathLength - drawLength;

          if (scrollPercentage >= 0.99) {
            path.style.strokeDasharray = "none";
            
          } else {
            path.style.strokeDasharray = pathLength + ' ' + pathLength;
          }

          var drawLength2 = pathLength2 * scrollPercentage;
          path2.style.strokeDashoffset = pathLength2 - drawLength2;
          if (scrollPercentage >= 0.99) {
            path2.style.strokeDasharray = "none";
            
          } else {
            path2.style.strokeDasharray = pathLength2 + ' ' + pathLength2;
          }


          if (scrollPercentage >= 0.99) {
            $(path4).css({'height':'100%'});
            
          } else {
            $(path4).css({'height': parseInt(scrollPercentage*100)+'%'});
           
          }
          
        });

    }

    if($('#profile-line-1-svg').length > 0 && $('#profile-line-2-svg').length > 0){

        var path1h = $('#profile-line-1-svg').find('path')[0];

        var pathLength1h = path1h.getTotalLength();

        path1h.style.strokeDasharray = pathLength1h + ' ' + pathLength1h;

        path1h.style.strokeDashoffset = pathLength1h;
        path1h.getBoundingClientRect();


        var path2h = $('#profile-line-2-svg').find('path')[0];

        var pathLength2h = path2h.getTotalLength();

        path2h.style.strokeDasharray = pathLength2h + ' ' + pathLength2h;

        path2h.style.strokeDashoffset = pathLength2h;
        path2h.getBoundingClientRect();

        window.addEventListener("scroll", function(e) {

          var scrollPercentage = (document.documentElement.scrollTop + document.body.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight);
          
          var drawLength2h = pathLength2h * scrollPercentage;
          path2h.style.strokeDashoffset = pathLength2h - drawLength2h;
          if (scrollPercentage >= 0.99) {
            path2h.style.strokeDasharray = "none";
            
          } else {
            path2h.style.strokeDasharray = pathLength2h + ' ' + pathLength2h;
          }

          var drawLength1h = pathLength1h * scrollPercentage;
          path1h.style.strokeDashoffset = pathLength1h - drawLength1h;
          if (scrollPercentage >= 0.99) {
            path1h.style.strokeDasharray = "none";
            
          } else {
            path1h.style.strokeDasharray = pathLength1h + ' ' + pathLength1h;
          }
          
        });

    }

});



// Search by ALL
$.filter_sitemap_search = function(value,$el = 'body'){

    var searchBy = {'q':value};
    var url = $('#search-form').attr('action');

    if(url){
      $.ajax({
            method: "post",
            url: url,
            data: searchBy,
            success: function(data) {
                console.log($($el))
                  if(data){
                      $($el).html(data);
                  }

                
                                     
            },
            error: function(data) {

                  // window.location.reload(true);
          }
      });
    }
   

}


$.fn.isInViewport = function() {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportMiddle = viewportTop + ($(window).height()/2) + 120;

    if(elementBottom > viewportTop && elementTop < viewportMiddle){
       if($(this).data('viewport') != floatingIndex){
          floatingIndex = $(this).data('viewport');

       }

        return true;
    }else
      return false;
};

$.checkViewports = function(){

  $('.viewport-show').each(function() {
    var self = $(this);
    
    if (self.isInViewport()) {
      setTimeout(function(){
        $.updateViewPort();
      },300)
    }
  });
}


$.append_notification = function(status,text_content){

   
    var randomString = 'notification_'+Math.random().toString(36).substring(7);
    var notification_html = '<div class="border2 closable '+status+' notification slide_notification '+randomString+'">'+
                                '<a class="close_toast" href="javascript:;"><span class="fas fa-times"></span></a>'+
                                '<p>'+text_content+'</p>'+
                            '</div>';

    jQuery('#notification_content').prepend(notification_html);

    setTimeout(function(){
        jQuery('div.'+randomString).removeClass('slide_notification');
    },250);

    //trigger the automatic slideUp;
    $.hide_notification(jQuery('div.'+randomString));
}


$.hide_notification = function ($div,click){
    click = (click) ? true : false;

    if (click){
        trigger_hide_notification();
    }else{
        setTimeout(function(){
            trigger_hide_notification();
        },7000);
    }

    function trigger_hide_notification(){
        $div.addClass('slide_notification');
        setTimeout(function(){
            $div.remove();
        },300);
    }
}


$.updateViewPort = function(){

  var partsViewport = $('.one-page-scroll').find('.part-bg');
  $(partsViewport).removeClass('active');
  $('.one-page-scroll').find('.active-part').text(floatingIndex);

  var activePart = $(partsViewport)[parseInt(floatingIndex)-1];
  $(activePart).addClass('active');

}


$.helpCenterEvents = function(){
  var stepShow = 1;
  $('#help-center-section').on('click','a.tab-link', function (e) {
    e.preventDefault();
    // First Hide all other tabs and remove link class
    $('#help-center-section').find('.tab-pane').removeClass('show').removeClass('active');
    $('#help-center-section').find('a.tab-link').removeClass('active').removeClass('mobile-opened').addClass('mobile-hide');

    var idShow = $(this).attr('href').replace("#", "");

    $('#help-center-section').find('div[id="'+idShow+'"]').tab('show');
    $(this).addClass('active').removeClass('mobile-hide').addClass('mobile-opened');

    $(this).next('.tab-pane').addClass('mobile-show');

    if($(window).width() < 767 || openedAsPopup){
       $('#help-center-title').hide();
       $('#help-center-button').show();
    }

    stepShow = 2;


    return false;
  })

  $('#help-center-section').on('click','a.hc-option-name', function (e) {
    e.preventDefault();
    // First Hide all other tabs and remove link class
    $(this).closest('.tab-pane').find('.collapse').removeClass('show').removeClass('mobile-show');
    $(this).closest('.tab-pane').find('a.hc-option-name').addClass('collapsed').removeClass('mobile-opened').addClass('mobile-hide');

    var idShow = $(this).attr('href').replace("#", "");

    $('#help-center-section').find('div[id="'+idShow+'"]').addClass('show').addClass('mobile-show');
    $(this).removeClass('collapsed').removeClass('mobile-hide').addClass('mobile-opened');

    $(this).closest('.tab-pane').prev('.tab-link').addClass('mobile-hide').removeClass('mobile-opened');
    stepShow = 3;

    if($(window).width() < 767  || openedAsPopup){
       $('#help-center-title').hide();
       $('#help-center-button').show();
    }

    return false;
  });

  $('#help-center-button').on('click','a#back-btn-help-center', function (e) {
    e.preventDefault();


    if(stepShow == 2){
      var activePane = $('#help-center-section').find('.tab-pane.mobile-show');            

      $(activePane).prev('a.tab-link').removeClass('active').removeClass('mobile-opened').removeClass('mobile-hide');           
      $(activePane).closest('.tab-content').find('.tab-link.mobile-hide').removeClass('mobile-hide');           
      $(activePane).removeClass('show').removeClass('active').removeClass('mobile-show');

      stepShow = 1;
      if($(window).width() < 767  || openedAsPopup){
         $('#help-center-title').show();
         $('#help-center-button').hide();
      }

    }

    if(stepShow == 3){

      var activeColapse = $('#help-center-section').find('.collapse.show.mobile-show');  
      var activePane = $('#help-center-section').find('.tab-pane.mobile-show');   

      $(activeColapse).removeClass('show').removeClass('mobile-show');
      $(activeColapse).closest('.tab-pane').find('a.hc-option-name').addClass('collapsed').removeClass('mobile-opened').removeClass('mobile-hide');
      $(activeColapse).closest('.tab-pane').addClass('active').removeClass('mobile-hide').addClass('mobile-opened');

      $(activePane).prev('a.tab-link').removeClass('mobile-hide').addClass('active').addClass('mobile-opened');  
      
      stepShow = 2;
    }
    

    return false;
  });

  $('#help-center-opener').on('click','a#close-btn-help-center', function (e) {

    $('#help-center-opener').empty().removeClass('opened');
    return false;
  });

}


jQuery.fn.validate = function(rules,callback){
    var FUNCTIONS = {
        bigger : function(object,rule){
            if (jQuery(object).val() > rule)
              return true;
            return false;
        },
        longer : function(object,rule){
            var value = jQuery(object).val();                
            if (value.length > rule) 
                return true;
            return false;
        },
        shorter : function(object,rule){
              var value = jQuery(object).val();
              if (value.length < rule) 
                  return true;
              return false;
        },
        eq : function(object,rule){

              var value = jQuery(object).val();
              if (value.length < rule) 
                  return true;
              return false;
        },
        validEmail : function(object,rule){
              var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return re.test(jQuery(object).val());
        },
        lesser : function(object,rule){
          if (jQuery(object).val() < rule) return true;
          return false;
        },
        equal : function(object,rule){
          if(jQuery(object).val() == rule) return true;
          return false;
        },
        isChecked : function(object,rule){
          if(jQuery(object).prop('checked')){
            jQuery(object).val('1');
          }
          else{
            jQuery(object).val('');
          }
        },
        hasValue : function(object,rule){
          var value = jQuery(object).val();

          if(!value || value=='' || value==' ' || value == null || typeof value == 'undefined') 
            return false;
          else
            return true;
        },
        isInt : function(object,rule){
          var value = jQuery(object).val();
          if (rule=='1'){
            return parseInt(value) % 1 == 0;
          }
          else
            return !(parseInt(value) % 1 == 0);
        }
    };

    var data_rules = jQuery(this).attr('data-rules') || '',
        rules = rules || {};

    var rules_split = data_rules.split(';');

        jQuery.each(rules_split,function(i,rule){
            var matches = rule.split(':');
            if (matches[0] && matches[1])
              rules[matches[0]] = matches[1];
        });
    
    if (!jQuery.isEmptyObject(rules)){

        var rArray=[true],
            that = this;

        jQuery.each(rules,function(aRule,aValue){
            var callee = FUNCTIONS[aRule];
            if (callee)
                rArray.push(callee(that,aValue));
        });

        return (jQuery.inArray(false, rArray)==-1);
    }
    else{
      return true;
    }

}


jQuery.fn.executeValidation = function(){

  var validArray = [],
      message = '',
      o = {},
      valid = true;



  jQuery(this).each(function(i,k){
      
      var validation = jQuery(k).validate(),
          data_validation_message = jQuery(k).attr('data-validation-message');

      if (!validation){ 
          jQuery(k).addClass('not-valid');
          message += '<p>'+data_validation_message+'</p>';
      }
      else{
          jQuery(k).removeClass('not-valid');
      }

      var name = jQuery(k).attr('name'),
          val = jQuery(k).val();

       if (o[name]) {
           if (!o[name].push) {
               o[name] = [o[name]];
           }
           o[name].push(val || '');
       } else {
           o[name] = val || '';
       }              

      validArray.push({
          'dom' : k,
          'valid' : validation,
          'message' : data_validation_message
      });

  });

  jQuery.each(validArray,function(i,k){
      if (!k.valid){
          valid = false;
          return false;
      }
  });
  return {'status':valid,'message':message,'validArray':validArray,'o':o};
}