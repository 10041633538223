(function ($) {
    $.fn.jSwitch = function (o){return new $.jSwitch(this,o);};
    $.jSwitch =  function(el,o){

        this.options = $.extend({}, $.jSwitch.defaults, o || {});
        var $jSwitch = this;

        $(el).each(function(i,k){
            var src = $(k).attr('data-src');
            if(src.length > 0){
                var image = new Image();
                image.onload = function(){
                    $(k).attr('src',src);
                    $jSwitch.options.onLoad.apply(this,$(k));
                };
                image.onerror = function(){
                    $(k).attr('src',$jSwitch.options.placeholder_url);
                    $jSwitch.options.onError.apply(this,$(k));
                };
                image.src = src;
            }
            else{
                $(k).attr('src',$jSwitch.options.placeholder_url);
                $jSwitch.options.onError.apply(this,$(k));
            }
        });
    };

    $.jSwitch.defaults = {
        placeholder_url:'/images/noimage.jpg',
        onLoad : function(){},
        onError : function(){}
    };
    var $jSwitch = $.jSwitch;
    $jSwitch.fn = $jSwitch.prototype = {jSwitch: '0.0.3'};
    $jSwitch.fn.extend=$jSwitch.extend=$.extend;
    $jSwitch.fn.extend({});
})(jQuery);